import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { data } from './Portfolio'
import styled from 'styled-components'

const Images = styled.div`
    width: 70%;
    margin: 0 auto;
    margin-top: 50px;
    padding-bottom: 50px;

    img {
        width: 100%;
    }

    @media (max-width: 800px) {
        width: 100%;
        margin-top: 50px;
    }
`

const Case = () => {
    const { id } = useParams()
    const history = useHistory()
    let c = data.find((i) => i.id.toLowerCase() == id)

    if (!c) {
        history.push('/portfolio')
    }

    return (
        <div className="case">
            <div className="case-header">
                <div className="case-title">
                    <span>{c.introline}</span>
                    <span>{c.introline}</span>
                    <span>{c.introline}</span>
                    <h1>{c.introline}</h1>
                </div>
                <div className="case-information">
                    <div className="case-information__column large">
                        <h2>What's this?</h2>
                        <span>{c.content.text}</span>
                        {c.link && (
                            <button className="btn">view website</button>
                        )}
                    </div>
                    <div className="case-information__column">
                        <div className="case-information__section">
                            <h2>Client</h2>
                            <span>{c.content.client}</span>
                        </div>
                        <div className="case-information__section">
                            <h2>Focus</h2>
                            <span>{c.content.focus}</span>
                        </div>
                        {/* <div className="case-information__section">
                            <h2>Awards</h2>
                            <span>CCP Bronze E-Commerce</span>
                        </div> */}
                    </div>
                </div>
            </div>
            <Images>
                <img src={c.content.image} alt="" />
            </Images>
        </div>
    )
}

export default Case
