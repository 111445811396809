import React from 'react';
import { becomeSad } from '../lib/audio';

const Music = () => {
    const [clicked, setClicked] = React.useState(false)

    const clickHandler = () => {
        setClicked(true)
        becomeSad()
    }

    return (
        <div className={`music ${clicked && "shaking"}`}>
            <img onClick={() => clickHandler()} src="https://www.nawpic.com/media/2020/xxtenations-nawpic-1.png"/>
        </div>
    )
}

export default Music;