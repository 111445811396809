import React, { useRef, useEffect } from 'react';

const Cursor = () => {
    // REFS
    const circle = useRef(null)
    const cursor = useRef(null)
    const circleWrapper = useRef(null)

    useEffect(() => {
        const cursorS = cursor.current.style,
              circleS = circleWrapper.current.style,
              hideParam = "translate(-50%, -50%) scale(0)"

        window.addEventListener("mousemove", 
            e => {
                const move = (item) => {
                    item.current.style.left = `${e.clientX}px`
                    item.current.style.top = `${e.clientY}px`
                }

                // setRotationDistance({
                //     x: (e.clientX-window.innerWidth/2)/50,
                //     y: (e.clientY-window.innerHeight/2)/50,
                // })

                move(circle)
                move(cursor)

                if(circleS.opacity == "") {
                    circleS.opacity = ".3"
                }

                if(e.clientY < 10 || window.innerHeight - e.clientY < 10) {
                    circleS.opacity = ""
                    cursorS.transform = hideParam
                } else {
                    if(cursorS.transform == hideParam) {
                        cursorS.transform = "translate(-50%, -50%) scale(.2)"
                    }
                }
            }
        )
    }, [])

    return (
        <>
            <div className="circle-wrapper" ref={circleWrapper}>
                <svg ref={circle} viewBox="0 0 752 741" fill="none" xmlns="http://www.w3.org/2000/svg" className="circle-bg-orange">
                    <g opacity=".3" filter="url(#filter0_f)"><ellipse rx="175.578" ry="170.103" transform="matrix(.99996 -.00897 .03143 .9995 375.917 370.88)" fill="#FF4016"></ellipse></g><defs><filter id="filter0_f" x=".262" y=".854" width="751.309" height="740.052" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend><feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur"></feGaussianBlur></filter></defs>
                </svg>
            </div>
            <div ref={cursor} className="custom-cursor"></div>
        </>
    )
}

export default Cursor;