import React, { useRef, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { TweenMax, Expo } from 'gsap'
import AL from '../components/reusable/AnimatedLetters'
import { playClick, playHover, playRouting, stopHover } from '../lib/audio'
import Socials from '../components/reusable/Socials'

const Home = (props) => {
    // REFS
    let description = useRef()
    let nav = useRef()
    let hand = useRef()

    useEffect(() => {
        document.body.classList.add('home-opened')
        playRouting()

        let titleY = 200,
            navigationY = 300

        if (window.pageXOffset < 700) {
            navigationY = 100
        }

        TweenMax.from(description, 3, {
            y: 250,
            ease: 'power4.out',
            delay: 0.8,
            skewY: 10,
            stagger: {
                amount: 0.4,
            },
        })

        TweenMax.from(nav, 5, {
            y: navigationY,
            ease: 'power4.out',
            skewY: 10,
            stagger: {
                amount: 0.4,
            },
        })

        TweenMax.from(hand, 1, {
            delay: 1.2,
            opacity: 0,
            y: -30,
            stagger: {
                amount: 0.4,
            },
            ease: Expo.easeInOut,
        })

        return () => {
            document.body.classList.remove('home-opened')
        }
    }, [])

    const navigation = [
        // {
        // 	id: '01',
        // 	page: 'Telegram',
        // 	path: '/',
        // 	link: '//t.me/dan_fedotoff',
        // },
        {
            id: '01',
            page: 'About',
            path: '/about',
        },
        {
            id: '02',
            page: 'Portfolio',
            path: '/portfolio',
        },
        {
            id: '03',
            page: 'Contacts',
            path: '/contacts',
        },
    ]

    return (
        <main className="transition-fade home">
            <div style={{ overflowY: 'hidden' }} className="home__wrapper">
                <div className="home__content">
                    <div className="home__title">
                        <div className="home__title-wrapper">
                            <h1 className="home__title-text">
                                <AL title="Hello," />
                                <br />
                                <AL title="i'm" /> <AL title="Daniil" />
                            </h1>
                        </div>
                        <span
                            ref={(el) => {
                                hand = el
                            }}
                            className="home__hand"
                        >
                            <span className="home__hand-icon"></span>
                        </span>
                    </div>
                    <div className="home__description-wrapper">
                        <p
                            className="home__description"
                            ref={(el) => {
                                description = el
                            }}
                        >
                            <span>I'm a UX/UI and product designer </span>
                            <br />
                            <span>turning fresh ideas into </span>
                            <br />
                            <span>amazing products. </span>
                        </p>
                    </div>
                    <div className="home__social-wrapper">
                        <Socials />
                    </div>
                </div>

                <div className="home__navigation">
                    {/* <div className="home__navigation__arrows">
                        <span className="home__navigation__arrow" style="transform: translate(0%, 0px); opacity: 1; visibility: visible;">&gt;</span>
                        <span className="home__navigation__arrow" style="transform: translate(0%, 0px); opacity: 0; visibility: hidden;">&gt;</span>
                    </div> */}
                    <ul
                        ref={(el) => {
                            nav = el
                        }}
                        className="home__navigation__list"
                    >
                        {navigation.map((i) => (
                            <li className="home__navigation__item">
                                <span className="home__navigation__number">
                                    <AL title={i.id} />
                                </span>
                                <NavLink
                                    className="home__navigation__link"
                                    to={i.path}
                                    onMouseEnter={playHover}
                                    onMouseLeave={stopHover}
                                    onMouseDown={playClick}
                                    onClick={() =>
                                        i.link && window.open(i.link)
                                    }
                                >
                                    <AL title={i.page} />
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </main>
    )
}

export default Home
