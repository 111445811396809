import React, { useEffect, useState } from 'react'
import { playClick, playRouting } from '../lib/audio'
import AL from '../components/reusable/AnimatedLetters'

const Contacts = () => {
	const [stage, setStage] = useState(1)
	const [message, setMessage] = useState('')

	useEffect(() => {
		document.body.classList.add('contacts-opened')
		playRouting()

		return () => {
			document.body.classList.remove('contacts-opened')
		}
	}, [])

	const switchStage = () => {
		playClick()
		setStage(2)
		setMessage('')
	}

	return (
		<div className="contacts">
			<div className="contact__wrapper">
				<div className="contact__column">
					<h1 className="contact__title">
						<AL title="Hello" />
					</h1>
					<form className="contact__form">
						<label
							className="contact__form__label active"
							for="message"
						>
							<textarea
								className="contact__form__field contact__form__field--textarea"
								maxlength="280"
								value={message}
								onChange={(e) => setMessage(e.target.value)}
								placeholder={
									stage == 1
										? 'Type your message here...'
										: 'Type here your email...'
								}
								required=""
							></textarea>
							<span className="contact__form__error"></span>
						</label>

						{/* <label className="contact__form__label" data-index="1" for="email">
                            <input className="contact__form__field" id="email" name="email" type="email" placeholder="Type here your email..." required=""/>
                            <span className="contact__form__error"></span>
                        </label> */}

						<p className="contact__form__label" data-index="2">
							<span className="contact__form__message contact__form__message--success">
								Your message was received, we will reply as soon
								as possible.
							</span>
							<span className="contact__form__message contact__form__message--failure">
								An error occurred on the server. Please try
								again later.
							</span>
						</p>
						<div className="contact__form__footer">
							<div className="contact__form__steps__steps">
								<div
									className={`contact__form__steps__step ${
										stage == 1 ? 'active' : ''
									}`}
								></div>
								<div
									className={`contact__form__steps__step ${
										stage == 2 ? 'active' : ''
									}`}
								></div>
							</div>
							<button
								className="contact__form__next active"
								type="button"
								onClick={() =>
									stage == 1
										? switchStage()
										: window.location.replace('/')
								}
							>
								Next
							</button>
							<button
								className="contact__form__submit"
								type="submit"
							>
								Send
							</button>
						</div>
					</form>
				</div>
				<div className="contact__column">
					<h2 className="contact__subtitle">Get in touch with me.</h2>
					<p className="contact__description">
						I'm always interested about cool stuff. <br />
						Are you minding a project? Let's discuss <br />
						your project and see how we can turn it into an amazing
						experience
					</p>
					<div className="contact__information">
						<div className="contact__information__section">
							<h3 className="contact__information__title">
								Socials
							</h3>
							<p className="contact__information__text">
								<span
									onClick={() =>
										window.open('//vk.com/daahn')
									}
								>
									Vkontakte
								</span>
								<br />
								<span
									onClick={() =>
										window.open('//instagram.com/dan_fedotoff/')
									}
								>
									Instagram
								</span>
								<br />
								<span
									onClick={() =>
										window.open('//t.me/dan_fedotoff')
									}
								>
									Telegram
								</span>
							</p>
						</div>
						<div className="contact__information__section">
							<h3 className="contact__information__title">
								Location
							</h3>
							<p className="contact__information__text">
								Kazakhstan, Astana
								<br />
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Contacts
