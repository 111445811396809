import { TweenMax } from 'gsap';
import React from 'react';

const Socials = (props) => {
    let social = React.useRef()

    React.useEffect(() => {
        TweenMax.from(social, 3, {
            y: 200,
            ease: "power4.out",
            delay: .8,
            skewY: 10,
            stagger: {
                amount: 0.4
            }
        })
    }, [])

    const socials = [
        {
            name: "Vkontakte",
            link: "//vk.com/daahn"
        },
        {
            name: "Telegram",
            link: "//t.me/dan_fedotoff"
        },
        // {
        //     name: "Behance",
        //     link: "#"
        // },
        // {
        //     name: "Dribbble",
        //     link: "//"
        // }
    ]

    return (
        <div ref={el => {social = el}} className={`home__social ${props.mobile ? "mobile" : ""}`}>
            <p className="home__social-text">Hang out with me @</p>
            <ul className="home__social__list">
                {
                    socials.map(i => (
                        <li
                            className="home__social__item"
                        >
                            <a className="home__social__link" rel="noopener" target="_blank" href={i.link}>
                                {i.name}
                            </a>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default Socials;