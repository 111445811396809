import { useEffect, useState } from 'react'
import './index.css'
import './css/trash.scss'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Header from './components/Header'
import Cursor from './components/Cursor'
import About from './pages/About'
import Contacts from './pages/Contacts'
import Music from './components/Music'
import HeartCanvas from './components/reusable/HeartCanvas'
import Room from './components/reusable/Room'
import Case from './pages/Case'
import Home from './pages/Home'
import Portfolio from './pages/Portfolio'
// import HeartCanvas from './components/reusable/HeartCanvas'

const App = () => {
    const [animate, setAnimate] = useState(false)

    const [rotationDistance, setRotationDistance] = useState({ x: 0, y: 0 })

    const hoverCursor = (action) => {
        let scale = action == 'enter' ? 6 : 2

        // cursor.current.style.transform = `translate(-50%, -50%) scale(0.${scale})`
    }

    useEffect(() => {
        document.body.classList.add('loaded')
    }, [])

    return (
        <Router>
            {/* <Room rd={rotationDistance}/> */}
            <HeartCanvas />
            <div className="home-page">
                <audio className="sound" data-source="hover">
                    <source src="/audio/hover.mp3" type="audio/mpeg" />
                </audio>
                {/* <img className="home__bg-img" src="img/grain.png" alt=""/> */}
                <div className="grain"></div>
                <Cursor />
                <Header />
                <div className="shades"></div>
                <Switch>
                    <Route path="/contacts">
                        <Contacts />
                    </Route>
                    <Route path="/about">
                        <About />
                    </Route>
                    <Route path="/music">
                        <Music />
                    </Route>
                    <Route path="/portfolio">
                        <Portfolio />
                    </Route>
                    <Route path="/case/:id">
                        <Case />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
                {/* <Slider /> */}
            </div>
        </Router>
    )
}

export default App
