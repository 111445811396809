import React, { useEffect, useRef } from 'react'
import AL from '../components/reusable/AnimatedLetters'
import { TweenMax } from 'gsap'
import Marquee from 'react-fast-marquee'
import SmoothList from 'react-smooth-list'
import { playRouting } from '../lib/audio'

const About = () => {
    let description = useRef()
    let text = useRef()

    useEffect(() => {
        document.body.classList.add('about-opened')
        playRouting()

        TweenMax.from(description, 3, {
            y: 250,
            ease: 'power4.out',
            delay: 0.6,
            skewY: 10,
            stagger: {
                amount: 0.4,
            },
        })

        TweenMax.from(text, 3, {
            y: 600,
            ease: 'power4.out',
            delay: 0.8,
            skewY: 10,
            stagger: {
                amount: 0.4,
            },
        })

        return () => {
            document.body.classList.remove('about-opened')
        }
    }, [])

    return (
        <main className="about">
            <section className="about__main">
                <div className="el-wrapper">
                    <h2 className="about__subtitle">
                        <AL title="About &nbsp; me" />
                    </h2>
                </div>
                <div className="el-wrapper">
                    <h1 className="about__title">
                        <AL title="UX/UI" />
                        <br />
                        <AL title="Designer" />
                    </h1>
                </div>
                <div className="el-wrapper">
                    <p
                        className="about__description"
                        ref={(el) => {
                            description = el
                        }}
                    >
                        <span>
                            Web Designer and UX/UI enthusiast based in
                            Kazakhstan.
                        </span>
                        <br />
                        <span>I have serious passion for responsive, smooth UI</span>
                        <br />
                        <span>
                            and creating intuitive UX.
                        </span>
                        <br />
                    </p>
                </div>
                <div className="el-wrapper">
                    <p
                        className="about__more-description"
                        ref={(el) => {
                            text = el
                        }}
                    >
                        I'm a <strong>UX/UI and product designer</strong> turning fresh ideas into amazing products. I specialize in delivering customized solutions to companies worldwide, continuously pushing the boundaries of my work to achieve the highest standards of quality.
                    </p>
                </div>
            </section>

            <Marquee
                className="about__services__skills-row"
                gradient={false}
                speed={300}
                direction="left"
            >
                <span>What exactly do i do?</span>
                <span>What exactly do i do?</span>
            </Marquee>

            <section className="about__skills">
                <h2 className="about__skills__title">
                    my <br /> main
                    <br />
                    <strong>directions</strong>
                </h2>
                <SmoothList wrapperTag="ul" className="about__skills__list">
                    <li>Figma</li>
                    <li>Photoshop</li>
                    <li>Illustrator</li>
                    <li>UI/UX Design</li>
                    <li>Web Design</li>
                </SmoothList>
            </section>
        </main>
    )
}

export default About
