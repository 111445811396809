import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
// import './styles.css'

const Date = styled.div`
    color: #8d6f57;
    display: block;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    line-height: 1.2rem;
    pointer-events: none;
    position: absolute;
    top: -20px;
    left: 0;
`

const Arrows = styled.div`
    left: 22rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 700px) {
        display: none;
    }
`

const Scrollers = styled.div`
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 9;

    div {
        font-weight: 900;
        color: #8d6f57;
        font-size: 4rem;
        line-height: 1;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 0.1rem;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid #8d6f57;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media (min-width: 700px) {
        display: none;
    }
`

const Arrow = styled.div`
    font-weight: 900;
    color: #8d6f57;
    display: inline-block;
    font-size: 8rem;
    line-height: 1;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 0.1rem;

    &:first-child {
        margin-right: -0.6rem;
        opacity: 0.4;
    }
`

export let data = [
    {
        introline: 'Filmcuts',
        year: 2024,
        id: 'Filmcuts',
        content: {
            link: null,
            client: 'Marketplace Company',
            focus: 'Art direction, Development, Web Design, UI/UX',
            image: '/assets/cases/1.png',
            text: 'Filmcuts not only offers a rich library of video content but also impresses users with its modern and intuitive design. I paid special attention to the user experience, creating an interface that is not only visually appealing but also ensures ease of navigation.',
        },
    },
    {
        introline: 'InsideEx',
        year: 2023,
        id: 'InsideEx',
        content: {
            link: null,
            client: 'Crypto Company',
            focus: 'Art direction, Development, Web Design, UI/UX',
            image: '/assets/cases/5.png',
            text: 'InsideEx is an innovative cryptocurrency exchange, designed with modern market requirements in mind and a commitment to providing convenience and security to users.',
        },
    },
    {
        introline: 'VipProkat',
        year: 2023,
        id: 'VipProkat',
        content: {
            link: null,
            client: 'Car Rental Company',
            focus: 'Development, Web Design, UI/UX',
            image: '/assets/cases/9.png',
            text: 'Visitors can easily browse through a collection of high-end vehicles, check availability, and make reservations effortlessly.',
        },
    },
    {
        introline: 'Genumm',
        year: 2022,
        id: 'Genumm',
        content: {
            link: null,
            client: 'Crypto Startup',
            focus: 'Web Design, UI/UX',
            image: '/assets/cases/2.png',
            text: 'Genumm is an impressive cryptocurrency project. Design offers users simplicity in navigation, making the Genumm platform not only functional but also visually appealing to a wide audience of crypto enthusiasts.',
        },
    },
    {
        introline: 'MoscowRent',
        year: 2023,
        id: 'MoscowRent',
        content: {
            link: null,
            client: 'Luxury Apartment Rent Company',
            focus: 'Web Design, UI/UX',
            image: '/assets/cases/7.png',
            text: "I've created a website for renting properties in Moscow City, featuring cost calculation and a well-executed UX/UI design. It offers a seamless user experience, allowing users to easily navigate through property listings, explore rental prices, and make informed decisions.",
        },
    },
    {
        introline: 'PlusClinic',
        year: 2022,
        id: 'PlusClinic',
        content: {
            link: null,
            client: 'Clinic Company',
            focus: 'Web Design, UI/UX',
            image: '/assets/cases/3.png',
            text: 'The design promotes a warm and inviting atmosphere, ensuring patients feel comfortable opening up and engaging in discussions about their health and well-being.',
        },
    },
    {
        introline: 'Mntn',
        year: 2022,
        id: 'Mntn',
        content: {
            link: null,
            client: 'Adventure Company',
            focus: 'Web Design, UI/UX',
            image: '/assets/cases/6.png',
            text: 'Website design captures the essence of travel with style. From breathtaking landscapes to cultural experiences, every aspect of the design is crafted to evoke the excitement and freedom of travel.',
        },
    },
    {
        introline: 'RentCourse',
        year: 2023,
        id: 'RentCourse',
        content: {
            link: null,
            client: 'Creator & Influencer',
            focus: 'Development, Web Design, UI/UX',
            image: '/assets/cases/4.png',
            text: 'With bold colors, modern fonts, and intuitive navigation, it inspires to learn and to support people in achieving success in their real estate careers.',
        },
    },
    {
        introline: 'Sponge',
        year: 2023,
        id: 'Sponge',
        content: {
            link: null,
            client: 'Crypto Startup',
            focus: 'Art direction, Development, Web Design, UI/UX',
            image: '/assets/cases/8.png',
            text: "The design offers clean aesthetics and intuitive navigation, providing visitors with a seamless experience as they explore the startup's offerings and learn about its mission in the crypto space.",
        },
    },
]

const Portfolio = () => {
    const [enabled, setEnabled] = useState(true)
    const [activeIndex, setActiveIndex] = useState(0)

    useEffect(() => {
        if (!enabled) {
            setTimeout(() => {
                setEnabled(true)
            }, 500)
        }
    }, [enabled])

    useEffect(() => {
        const handleScroll = (e) => {
            if (e.deltaY > 14) {
                setActiveIndex((prevIndex) =>
                    prevIndex == data.length - 1 ? 0 : prevIndex + 1
                )
                setEnabled(false)
            }
            if (e.deltaY < -14) {
                setActiveIndex((prevIndex) =>
                    prevIndex == 0 ? data.length - 1 : prevIndex - 1
                )
                setEnabled(false)
            }
        }

        if (enabled) {
            window.addEventListener('wheel', handleScroll)
        }

        return () => {
            window.removeEventListener('wheel', handleScroll)
        }
    }, [enabled])

    const halfwayIndex = Math.ceil(data.length / 2)
    const itemHeight = window.innerWidth < 700 ? 100 : 170
    const shuffleThreshold = halfwayIndex * itemHeight

    const determinePlacement = (itemIndex) => {
        if (activeIndex === itemIndex) return 0

        if (itemIndex >= halfwayIndex) {
            if (activeIndex > itemIndex - halfwayIndex) {
                return (itemIndex - activeIndex) * itemHeight
            } else {
                return -(data.length + activeIndex - itemIndex) * itemHeight
            }
        }

        if (itemIndex > activeIndex) {
            return (itemIndex - activeIndex) * itemHeight
        }

        if (itemIndex < activeIndex) {
            if ((activeIndex - itemIndex) * itemHeight >= shuffleThreshold) {
                return (data.length - (activeIndex - itemIndex)) * itemHeight
            }
            return -(activeIndex - itemIndex) * itemHeight
        }
    }

    const handleClick = (direction) => {
        setActiveIndex((prevIndex) => {
            if (direction === 'next') {
                if (prevIndex + 1 > data.length - 1) {
                    return 0
                }
                return prevIndex + 1
            }

            if (prevIndex - 1 < 0) {
                return data.length - 1
            }

            return prevIndex - 1
        })
    }

    return (
        <div className="carousel">
            <Scrollers>
                <div
                    onClick={() =>
                        setActiveIndex((prevIndex) =>
                            prevIndex == 0 ? data.length - 1 : prevIndex - 1
                        )
                    }
                >
                    &lt;
                </div>
                <div
                    onClick={() =>
                        setActiveIndex((prevIndex) =>
                            prevIndex == data.length - 1 ? 0 : prevIndex + 1
                        )
                    }
                >
                    &gt;
                </div>
            </Scrollers>
            <Arrows>
                <Arrow>&gt;</Arrow>
                <Arrow>&gt;</Arrow>
            </Arrows>
            <div className="slides">
                <div className="carousel-inner">
                    {data.map((item, i) => (
                        <NavLink to={`/case/${item.id.toLowerCase()}`}>
                            <button
                                type="button"
                                // onClick={() => setActiveIndex(i)}
                                className={cn('carousel-item', {
                                    active: activeIndex === i,
                                    visible:
                                        Math.abs(determinePlacement(i)) <= 600,
                                })}
                                key={item.id}
                                style={{
                                    transform: `translateY(${determinePlacement(
                                        i
                                    )}px)`,
                                }}
                                data-text={item.introline}
                            >
                                <Date>{item.year}</Date>
                                {item.introline}
                            </button>
                        </NavLink>
                    ))}
                </div>
            </div>
        </div>
        // <div className="container">
        //     <section className="outer-container">
        //         <div className="carousel-wrapper">
        //             <button
        //                 type="button"
        //                 className="carousel-button prev"
        //                 onClick={() => handleClick('prev')}
        //             >
        //                 fsef
        //             </button>

        //             <div className="carousel">
        //                 <div className="leading-text">
        //                     <p>{leadingText}</p>
        //                 </div>
        //                 <div className="slides">
        //                     <div className="carousel-inner">
        //                         {data.map((item, i) => (
        //                             <button
        //                                 type="button"
        //                                 onClick={() => setActiveIndex(i)}
        //                                 className={cn('carousel-item', {
        //                                     active: activeIndex === i,
        //                                     visible:
        //                                         Math.abs(
        //                                             determinePlacement(i)
        //                                         ) <= visibleStyleThreshold,
        //                                 })}
        //                                 key={item.id}
        //                                 style={{
        //                                     transform: `translateY(${determinePlacement(
        //                                         i
        //                                     )}px)`,
        //                                 }}
        //                             >
        //                                 {item.introline}
        //                             </button>
        //                         ))}
        //                     </div>
        //                 </div>
        //             </div>

        //             <button
        //                 type="button"
        //                 className="carousel-button next"
        //                 onClick={() => handleClick('next')}
        //             >
        //                 fsef
        //             </button>
        //         </div>
        //     </section>
        // </div>
    )
}

export default Portfolio
