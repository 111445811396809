let hoverSound = new Audio("../audio/hover.mp3"),
    routingSound = new Audio("../audio/slide.mp3"),
    desktop = window.innerWidth > 700

export const playHover = () => window.isSoundOn && desktop && hoverSound.play()
export const stopHover = () => {
    if(window.isSoundOn && desktop) {
        hoverSound.pause()
        hoverSound.currentTime = 0
    }
}

export const playClick = () => {
    if(window.isSoundOn && desktop) {
        let clickSound = new Audio("../audio/click.mp3")
        clickSound.play()
    }
}

export const playRouting = () => window.isSoundOn && desktop && routingSound.play()

export const becomeSad = () => {
    let soSad = new Audio("../audio/sad.mp3")
    soSad.play()
}